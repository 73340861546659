<template lang="pug">
  #user-testing-thanks
    h1 Thanks!
    p We're all done with the app.
    p Don't worry, you didn't miss anything. This was a really short test.
    p Please go to the next UserTesting task to answer a couple of questions.
</template>

<script>
export default {
  props: {},

  data() {
    return {}
  },

  computed: {},

  watch: {},

  methods: {},

  components: {},
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
</script>
